* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: #fff;
  font-size: 14px;
  height: 100%;
  line-height: 1.42857143;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans",
    "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: var(--bs-body-color);
  background-color: #fafbfd;
}

img,
picture,
video {
  display: block;
  max-width: 100%;
}

input,
textarea,
button,
select {
  font: inherit;
  border: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(50, 71, 92, 0.87);
  font-weight: 500;
  transition: var(--transation);
}

h1 {
  font-size: var(--f-96);
  line-height: var(--f-112);
}

h2 {
  font-size: var(--f-60);
  line-height: var(--f-72);
}

h3 {
  font-size: var(--f-48);
  line-height: var(--f-56);
}

h4 {
  font-size: var(--f-32);
  line-height: var(--f-40);
}

h5 {
  font-size: var(--f-24);
  line-height: var(--f-32);
}

h6 {
  font-size: var(--f-20);
  line-height: var(--f-32);
}

.search {
  width: max-content;
  height: max-content;
  padding: 10px;
  position: relative;

  svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 18px !important;
    height: 18px !important;
  }

  input {
    width: 400px;
    height: 40px;
    border-radius: 10px;
    padding-left: 35px;
    outline: none;
    font-size: 18px;
    border: 2px solid #979aa4;
  }
}

.app {
  min-width: calc(100vw - 15px);
  min-height: 100vh;
}

.form-group {
  margin-bottom: 0;
  padding-bottom: 20px;
}

#react-select-3-listbox {
  z-index: 100;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bold {
  font-weight: 600;
}

.green-txt {
  color: green;
}

.form__btns {
  display: flex;
  gap: 20px;

  .btn:last-child {
    color: #72809d;
  }

  span {
    text-decoration: underline;

    &:hover {
      color: #3498db;
    }
  }
}

.boolen-field {
  display: flex;
  justify-content: center;

  path {
    fill: #fff;
  }

  &--true {
    path {
      fill: rgb(108, 171, 13);
    }
  }

  &--false {
    path {
      fill: #72809d;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    padding-right: 2px;
    padding-top: 1px;
  }
}

.form__error {
  // position: absolute;
  margin-bottom: -10px;
  color: rgb(239, 92, 92);
}

.modal__bonus {
  .modal__content {
    overflow: visible;
  }

  .modal__box {
    overflow: visible;
  }
}

.form {
  &-content {
    margin-top: 20px;
  }

  &-price {
    margin-top: 20px;

    &__info {
      p {
        margin-bottom: 20px;
      }
    }
  }

  &__user-group {
    display: grid;
    gap: 20px;
    margin: 20px 0;
  }

  &-income-outcome,
  &-comment {
    margin: 30px 0;
  }
}

.modal {
  .btn-group {
    display: flex;
    gap: 10px;
  }

  &__create-position {
    padding: 20px;
  }

  &__similar {
    .btn-group {
      padding: 0 20px 20px 20px;
    }
  }

  &__comment {
    padding: 20px;
  }

  &__user-group {
    input {
      width: 1.143em;
      height: 1.143em;
      appearance: none;
    }

    .indeterminate {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: #6a6cf7;
      border: none;

      &::before {
        display: block;
        content: "";
        width: 5px;
        height: 2px;
        position: absolute;
        background-color: #fff;
      }
    }
  }

  &__basket {
    .form-group {
      min-width: 200px;
    }
  }
}

.tippy-popper {
  margin-top: -50px;
  transition: none !important;
  max-width: 200px;
}

.product {
  &__price {
    color: red;
    font-weight: 700;
    font-size: 20px;
  }

  &__lead-source {
    background-color: rgb(33, 37, 41);
    color: #fff;
    padding: 0.25rem;
    border-radius: 0.3rem;
    font-size: 11px;
  }
}

.order {
  &__phone {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: rgb(91, 192, 222);

    &:hover {
      text-decoration: underline;
    }
  }

  &__client-full-name {
    font-size: 2rem;
    color: rgb(83, 96, 97);
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  &__details {
    ul {
      margin: 20px 0;
    }

    li {
      display: grid;
      grid-template-columns: 1.2fr 1fr;
      list-style: none;

      padding: 10px 15px;
      border-bottom: 1px solid #c4d0d3;

      &:nth-child(2n) {
        background-color: #f7f9fb;
      }
    }
  }

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;

    color: rgb(255, 0, 0);
  }
}

.txt-danger {
  font-size: 1.5rem;
  color: rgb(230, 126, 34);
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.order-details {
  background-color: #e6e7fc;

  div {
    display: flex;
    gap: 8px;
  }
}

.order-count {
  color: rgb(255, 62, 29);
}

.without {
  &__scroll {
    .modal__box {
      overflow: visible;
    }

    .modal__content {
      overflow: visible;
    }
  }
}

.product {
  &__title {
    color: #3498db;
    font-size: 22px;
  }

  &__price {
    font-size: 24.5px;
  }
}

[disabled] {
  background-color: rgba(59, 59, 59, 0.1) !important;
}

.input-with-icon {
  position: relative;

  svg {
    width: 20px;
  }

  .icon {
    cursor: pointer;
    display: grid;
    place-items: center;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 5px;
    bottom: 12px;
  }
}

.row {
  @media (max-width: 600px) {
    display: block;
  }
}

.day-limit {
  .form-control:disabled {
    background-color: orange;
    color: #333;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }

  .form-control:disabled {
    color: orange;
  }
}

.tranzaksion-type {
  li {
    &:first-child {
      .form__radio {
        &.active {
          border-color: #86cb43;
          background-color: #86cb43;
        }
      }
    }

    &:last-child {
      .form__radio {
        border-color: #ee1e50;

        p {
          color: #ee1e50;
        }

        &.active {
          background-color: #ee1e50;
        }
      }
    }
  }
}

.row-status {
  &-active,
  &-inactive {
    .boolen-field {
      &--true {
        path {
          fill: #fff !important;
        }
      }

      &--false {
        path {
          fill: red !important;
        }
      }
    }
  }

  &-active {
    background-color: #6bc48d !important;
    color: #fff;
  }

  &-inactive {
    background-color: #ff9f7c !important;
    color: #fff;
  }
}

.action__delete {
  opacity: 0.2;
}

.close {
  cursor: pointer;
}

.badge {
  padding: 2px 10px;
  border-radius: 20px;

  &-primary {
    background-color: #3498db;
    color: #fff;
  }
}
